import React from "react"
import cx from "classnames"
import icon from "../../../svg/bw_icon.svg"
import close from "../../../svg/close.svg"
import style from "./mobileMenu.module.css"
import { NAV_ITEMS, HOME } from "../../../../utils/urls"
import { Link } from "gatsby"

const mobileMenu = ({ onClose, openMenu }) => {
  return (
    <div className={cx(style.menuColor, openMenu ? style.menuActive : null)}>
      <div className={style.menuItems}>
        <Link to={HOME}>
          <img
            src={icon}
            className={style.icon}
            alt="white color; Hector Benitez initials"
          />
        </Link>
        <img
          src={close}
          className={style.close}
          alt="white color; close button"
          onClick={onClose}
        />
      </div>
      <div className={style.menuBttnContainer}>
        {NAV_ITEMS.map(({ to, label }) => {
          return (
            <Link className={style.bttn} to={to}>
              {label}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default mobileMenu
