import React from "react"
import style from "./media.module.css"
import { SOCIAL_MEDIA_ICONS } from "../../utils/urls"

const SOCIAL_MEDIA = () => {
  return (
    <div className={style.socialParentContainer}>
      {SOCIAL_MEDIA_ICONS.map(({ icon, link }) => {
        return (
          <a
            href={link}
            className={style.socialMediaIcons}
            target="_blank"
            rel="noreferrer noopener"
          >
            {icon}
          </a>
        )
      })}
    </div>
  )
}

export default SOCIAL_MEDIA
