import React from "react"
import PropTypes from "prop-types"
import Navbar from "../components/common/nav"
import SOCIAL_MEDIA from "../components/Social Media"
import Footer from "../components/common/footer"

import "../style/app.css"
import { Helmet } from "react-helmet"

const Layout = ({ children, ...seoProps }) => {
  return (
    <>
      <Helmet
        defaultTitle={"My Portfolio"}
        titleTemplate={"Hector Benitez { %s }"}
        {...seoProps}
      >
        <meta name="description" content={seoProps.description} />
        <meta name="image" content={seoProps.seoImage} />
      </Helmet>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div style={{ flex: "1" }}>
          <Navbar />
          <main>{children}</main>
        </div>
        <SOCIAL_MEDIA />
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
