import React from 'react'
import style from './footer.module.css'

const Footer = () => {
    return(
        <footer className={style.footer}>
            &copy; Hector Benitez 2020
        </footer>
    )
}

export default Footer;