import React from "react"
import { Link } from "gatsby"
import style from "./navItems.module.css"
import { NAV_ITEMS } from "../../../../utils/urls"

const NavItems = () => {
  return (
    <ul className={style.navigationBar}>
      {NAV_ITEMS.map(({ to, label }) => {
        return (
          <li className={style.navigationItems}>
            <Link to={to}>{label}</Link>
          </li>
        )
      })}
    </ul>
  )
}

export default NavItems
