import React, { useState } from "react"

import { Link } from "gatsby"
import icon from "../../svg/name_icon.svg"
import mobileMenuIcon from "../../svg/mobile-menu.svg"
import style from "./nav.module.css"
import MobileMenu from "./MobileMenu"
import NavItems from "./NavItems"
import { HOME } from "../../../utils/urls"

const Nav = () => {
  const [openMenu, setOpenMenu] = useState(false)

  const handleMenuIconClick = () => {
    setOpenMenu(prevState => !prevState)
  }

  const handleCloseIconClick = () => {
    setOpenMenu(false)
  }

  return (
    <nav className={style.items}>
      <Link to={HOME}>
        <img
          src={icon}
          alt="Hector Benitez initials"
          className={style.initialBrandLogo}
        />
      </Link>
      <NavItems />
      <img
        src={mobileMenuIcon}
        alt="hamburger menu for mobile view of website"
        className={style.hamburgerIcon}
        onClick={handleMenuIconClick}
      />

      <MobileMenu openMenu={openMenu} onClose={handleCloseIconClick} />
    </nav>
  )
}

export default Nav
