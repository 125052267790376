import React from "react"
import {
  AiFillGithub,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai"
import PersonalProjectSvg from "../images/custom_projects_personal.svg"
import TufasProjectSvg from "../images/custom_projects_tufas.svg"
import LuxoProjectSvg from "../images/custom_projects_luxo.svg"
import style from "./socialMedia.module.css"

export const HOME = "/"
export const ABOUT = "/about"
export const PROJECTS = "/projects"
export const PERSONAL_PROJECTS = "/projects/me"
export const TUFAS_PROJECTS = "/projects/tufas"
export const LUXO_PROJECTS = "/projects/luxo"
export const CONTACT = "/contact"
export const SUCCESS = "/success"

export const GITHUB = "https://www.github.com/h-Benitez13"
export const IG = "https://www.instagram.com/sendcity_ni/"
export const LINKEDIN = "https://www.linkedin.com/in/hector-benitez/"

export const SOCIAL_MEDIA_ICONS = [
  {
    icon: <AiFillGithub className={style.icon} />,
    link: GITHUB,
  },
  {
    icon: <AiOutlineLinkedin className={style.icon} />,
    link: LINKEDIN,
  },
  {
    icon: <AiOutlineInstagram className={style.icon} />,
    link: IG,
  },
]
export const NAV_ITEMS = [
  {
    to: ABOUT,
    label: "About",
  },
  {
    to: PROJECTS,
    label: "Projects",
  },
  {
    to: CONTACT,
    label: "Contact",
  },
]

export const PROJECT_ITEMS = [
  {
    to: PERSONAL_PROJECTS,
    label: "My Portfolio",
    src: PersonalProjectSvg,
    projectLink: "https://hecben.dev",
  },
  {
    to: TUFAS_PROJECTS,
    label: "Tufas Boulder Lounge",
    src: TufasProjectSvg,
    projectLink: "https://tufasboulderlounge.com",
  },
  {
    to: LUXO_PROJECTS,
    label: "Luxo-music",
    src: LuxoProjectSvg,
    projectLink: "https://luxomusic.com",
  },
]
